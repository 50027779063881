import { FC, memo, useEffect } from 'react';
import useSteps from '../../TerritoryMembershipModal/hooks/useSteps';
import WelcomeToT2ModalContent from './WelcomeToT2ModalContent';
import { useAnalytics } from '../../AnalyticsProvider/AnalyticsProvider';
import { AnalyticsOnboardingModalStep } from '../constants';

type TModalContentContainerProps = {
  closeModal: () => void;
};

const ONBOARDING_MODAL_STEPS = [
  { analyticsStepName: AnalyticsOnboardingModalStep.welcomeToT2, component: WelcomeToT2ModalContent },
] as const;

const OnboardingModalContentContainer: FC<TModalContentContainerProps> = ({ closeModal }) => {
  const { sendEvent } = useAnalytics();
  const {
    currentStep: { component: CurrentStep, analyticsStepName: currentStepAnalyticsName },
    prevStep,
    nextStep,
    currentStepIndex,
  } = useSteps(ONBOARDING_MODAL_STEPS);

  useEffect(() => {
    sendEvent('onboarding_modal_step_view', { step_index: currentStepIndex, step_name: currentStepAnalyticsName });
  }, [currentStepIndex]);

  return <CurrentStep goToPreviousStep={prevStep} goToNextStep={nextStep} closeModal={closeModal} />;
};

export default memo(OnboardingModalContentContainer);
