import { getSessionCookieDomain } from 't2-keystone/src/services/session/getSessionCookieDomain';

export const GRAPHQL_API_URL =
  process.env.GRAPHQL_API_URL ||
  (process.env.RAILWAY_SERVICE_KEYSTONE_URL && `https://${process.env.RAILWAY_SERVICE_KEYSTONE_URL}/api/graphql`) ||
  process.env.NEXT_PUBLIC_GRAPHQL_API ||
  'http://localhost:3000/api/graphql';
export const PUBLIC_URL = process.env.PUBLIC_URL || 'https://t2.world';
export const ENV = process.env.RAILWAY_ENVIRONMENT || 'development';
export const POA_API_URL =
  process.env.POA_API_URL ||
  (process.env.RAILWAY_SERVICE_POA_SERVICE_URL && `https://${process.env.RAILWAY_SERVICE_POA_SERVICE_URL}`) ||
  'http://localhost:3002';
export const APOLLO_SERVER_CLIENT_MAX_AGE = +(process.env.APOLLO_SERVER_CLIENT_MAX_AGE || 5000);
export const ANALYTICS_SCRIPT_URL = process.env.ANALYTICS_SCRIPT_URL || '';
export const PROD_APP_URL = 'app.t2.world';

export const isProductionEnv = ENV === 'production';
export const isDevelopmentEnv = ENV === 'development';
export const isProductionNetwork = isProductionEnv;

export const KEYSTONE_URL =
  process.env.KEYSTONE_URL ||
  (process.env.RAILWAY_SERVICE_KEYSTONE_URL && `https://${process.env.RAILWAY_SERVICE_KEYSTONE_URL}`) ||
  'http://localhost:3000';

export const CLOUDINARY_CONFIG = {
  baseUrl: `https://${process.env.CLOUDINARY_API_DOMAIN || 'res.cloudinary.com'}`,
  cloudName: process.env.CLOUDINARY_CLOUD_NAME || 'dxhpzq6ej',
  folder: process.env.RAILWAY_ENVIRONMENT || process.env.CLOUDINARY_API_FOLDER || 't2-keystone',
};

export const POA_PING_EVENT_INTERVAL_IN_SECONDS = +(process.env.POA_PING_EVENT_INTERVAL_IN_SECONDS || 5);

export const HOTJAR_CONFIG = {
  ID: process.env.HOTJAR_ID,
  SV: process.env.HOTJAR_SV,
};

export const APP_URL =
  process.env.APP_URL ||
  (process.env.RAILWAY_SERVICE_APP_URL && `https://${process.env.RAILWAY_SERVICE_APP_URL}`) ||
  'http://localhost:4000';
// eslint-disable-next-line -- eslint would reformat this line to `const { TURNSTILE_SITE_KEY } = process.env`, but this would break exposing env var to frontend
export const TURNSTILE_SITE_KEY = process.env.TURNSTILE_SITE_KEY;
// eslint-disable-next-line -- eslint would reformat this line to `const { TURNSTILE_SITE_KEY } = process.env`, but this would break exposing env var to frontend
export const PRIVY_APP_ID = process.env.PRIVY_APP_ID;
// eslint-disable-next-line -- eslint would reformat this line to `const { TURNSTILE_SITE_KEY } = process.env`, but this would break exposing env var to frontend
export const PRIVY_APP_SECRET = process.env.PRIVY_APP_SECRET;

// eslint-disable-next-line prefer-destructuring
export const SENTRY_DSN = process.env.SENTRY_DSN;

// eslint-disable-next-line prefer-destructuring
export const ARWEAVE_GATEWAY_URL = process.env.ARWEAVE_GATEWAY_URL;

export const SESSION_COOKIE_DOMAIN =
  process.env.SESSION_COOKIE_DOMAIN || getSessionCookieDomain(process.env.RAILWAY_SERVICE_KEYSTONE_URL) || 'localhost';

// eslint-disable-next-line prefer-destructuring
export const FACEBOOK_PIXEL_ID = process.env.FACEBOOK_PIXEL_ID;
// eslint-disable-next-line prefer-destructuring
export const GA_MEASUREMENT_ID = process.env.GA_MEASUREMENT_ID;

// eslint-disable-next-line prefer-destructuring
export const ZORA_CREATE_REFERRAL_ACCOUNT_ADDRESS = process.env.ZORA_CREATE_REFERRAL_ACCOUNT_ADDRESS;

export const UNLOCK_NETWORK_ID = isProductionEnv ? 8453 : Number(process.env.PR_ENV_UNLOCK_NETWORK_ID) || 84532;
